.Carts {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    position: relative;
    min-height: 400px;
    overflow: visible;
}

.Color-rule, .Name-rule {
    font-size: 1.3rem;
    padding: 10px;
    background-color: white;
    color: #282c34;
    margin-left: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    display: inline-block;
}

.mb-m {
    margin-bottom: 20px;
}

.btn-primary {
    padding: 10px 15px;
    background-color: green;
    border: 0;
    color: white;
    font-size: 1.1rem;
    border-radius: 5px;
}
